'use strict';

// Refactored in a more OOP way to avoid issue of lost context (this|self) on importing within
// other JS modules.
let CartIconWidget = function () {
    this.container = null;
    /**
    * Initiate widget
    * @param {string} context - storefront|checkout
    * @return {*}
    */
    this.init = function () {
        var self = this;
        // container(s)
        self.container = $('.header-cart-icon');

        $(self).trigger('self:initialize');

        return self;
    };
    this.update = function () {
        var self = this;

        self.render();
        $(self).trigger('self:update');

        return self;
    };
    this.render = function () {
        var self = this;

        var cartStorage = localStorage.getItem('cart') || '{}';
        var cartModel = JSON.parse(cartStorage);

        if (Object.keys(cartModel).length === 0 || cartModel.numItems === 0) {
            this.container.each(function (i, e) {
                $(e).find('#cartwidgeticon-info').addClass('empty-cart-trigger');
                $(e).find('.minicart-quantity').addClass('empty-cart');
                $(e).find('.minicart-quantity').html('');
                $(e).find('.price-header').addClass('empty-cart');
                $(e).find('.price-header').html('');
            });
        } else {
            // @see https://gymgrossisten.atlassian.net/browse/SFCC-1815 for details why is used
            // adjustedMerchandizeTotalGrossPrice instead of grandTotal
            let totalPrice = cartModel?.totals?.adjustedMerchandizeTotalGrossPrice
                ? cartModel.totals.adjustedMerchandizeTotalGrossPrice
                : 0;
            this.container.each(function (i, e) {
                $(e).find('#cartwidgeticon-info').removeClass('empty-cart-trigger');
                $(e).find('.minicart-quantity').removeClass('empty-cart');
                $(e).find('.minicart-quantity').html(cartModel.numItems);
                $(e).find('.price-header').removeClass('empty-cart');
                $(e).find('.price-header').html(totalPrice);
            });
        }

        $(self).trigger('self:render');

        return self;
    };
    this.registerCallback = function (ids, func) {
        var self = this;

        if (!Array.isArray(ids)) {
            ids = [ids];
        }

        ids.forEach(function (id) {
            switch (id) {
            case 'carticon:initialize':
                $(self).on('self:init', func);
                break;
            case 'carticon:update':
                $(self).on('self:update', func);
                break;
            case 'carticon:render':
                $(self).on('self:render', func);
                break;
            default:
                console.error('state not covered', id);
            }
        });

        return self;
    };
};

module.exports = new CartIconWidget();
