'use strict';

// Script has differences #CDIFF #MONITOR BS <> GG <> PF <> SN <> FM
// Current version is BS-based with delta sync from other site-specific versions.
// SN app cartridge does not quick view script.

import FeatureFlagProvider from 'shared/js/featureFlagRegistrar';

var base = require('theme/js/product/base');
var quickView = require('base/product/quickView');


import FeatureFlagRegistrar from 'shared/js/featureFlagRegistrar';

// The next two functions:
// - showPreMinicartBox
// - showCartItemsHeader
// are migrated from ./detail.js for "updateQuantity" and "showPreMinicart", see those functions for more info.
function showPreMinicartBox(response) {
    $('.header').removeClass('scroll-down');
    $('.cart-box-items').empty();
    $('.cart-box-free-delivery-msg').empty();

    var $show = $('.added-to-cart-box').show();
    setTimeout(function () {
        $show.hide();
    }, 3000);
    $('.minicart .minicart-quantity').html(response.quantityTotal);
    response.cart.items.forEach(function (items) {
        if (response.pid == items.id) {
            $('.cart-box-items').append('<div class="cart-box-item">'
                + '<div class="cart-box-item-icon"><i class="icon-check"></i></div>'
                + '<div class="cart-box-item-title">'
                + '<span class="cart-box-item-title-name">'
                + items.productName
                + '</span>'
                + '<span class="cart-box-item-title-name-msg">' + response.addToCartMsg
                + '</span>'
                + '</div>'
                + '</div>');
        }
    });

    // #CDIFF #DONE BS <> SN
    // SN does not have giftCertificateLineItemInCart & isFreeDeliveryEnabled check
    if (!FeatureFlagRegistrar.isSite('SN')) {
        if (response.isFreeDeliveryEnabled == 'true' && !response.giftCertificateLineItemInCart) {
            if (response.leftToFreeDelivery > 0) {
                $('.cart-box-free-delivery-msg').append('<div class="minicart-message msg-free-delivery">'
                    + '<strong>' + response.freeDeliveryMsg + '</strong>'
                    + '</div>');
            } else {
                $('.cart-box-free-delivery-msg').append('<div class="minicart-message msg-free-delivery">'
                    + '<div class="cart-box-free-item-icon"><i class="icon-check"></i></div>'
                    + '<strong>' + response.freeDeliveryMsg + '</strong>'
                    + '</div>');
            }
        }
    }

    if (response.cart.hasBonusProduct || response.displayBonusProductMessage) {
        $('.msg-bonus-product').show();
    }
}

/**
 * Updates the small box to show what is added to minicart after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */

function showCartItemsHeader() {
    $('.minicart-quantity').removeClass('empty-cart');
    $('.minicart .icon-cart').removeClass('empty-cart-trigger');
    $('.price-header').removeClass('empty-cart');
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="quickview-modal modal fade" id="quickViewModal" role="dialog">'
        + '<div class="quickview-modal-dialog modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="quickview-modal-content modal-content">'
        + '<div class="quickview-modal-header modal-header">'
        + '<button type="button" class="quickview-modal-close-button icon-close" data-dismiss="modal"></button>'
        + '</div>'
        + '<div class="quickview-modal-body modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');

    return { body: body };
}

/**
 * Init product carousel in quick view modal
 */
function initProductCarousel() {
    $('.slick-product-carousel.desktop').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // Icons are preserved as they were before fix in SFCC-1764.
        // However, in product/base.js the same icon class is used also for BS and PF.
        prevArrow:
            FeatureFlagProvider.isSite('FM')
                ? "<i class='icon-arrow-left-right'></i>"
                : "<i class='icon-point'></i>",
        nextArrow:
            FeatureFlagProvider.isSite('FM')
                ? "<i class='icon-arrow-left-right right'></i>"
                : "<i class='icon-point-right'></i>",
    });

    $('.product-swatch-images .swatch').click(function () {
        if (!$(this).hasClass('active')) {
            $(this).siblings('.active').removeClass('active');
            $(this).addClass('active');

            $('.slick-product-carousel.desktop').slick('slickGoTo', ($(this).index()), true);
        }
    });

    $('.slick-product-carousel.desktop').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var productSwatchImages = $('.product-swatch-images .swatch');
        productSwatchImages.siblings('.active').removeClass('active');
        productSwatchImages.eq(nextSlide).addClass('active');
    });
}

/**
 * Render Content Assets in Quick View
 */
function renderPdpContentAssets() {
    var productUsps = $('#product-usps').html(),
        pdpOutOfStockPopupConsent = $('#PDP-out-of-stock-popup-consent').html(),
        pdpOutOfStockDisclaimer = $('#PDP-out-of-stock-disclaimer').html();

    $('#quickViewModal .product-usps-container').html(productUsps);
    $('#quickViewModal .PDP-out-of-stock-popup-consent-asset').html(pdpOutOfStockPopupConsent);
    $('#quickViewModal .product-outofstock-disclaimer').html(pdpOutOfStockDisclaimer);
}

function togglePopup() {
    $('.product-outofstock-popup-close, .product-outofstock-popup-cancel').click(function (e) {
        e.preventDefault();
        $('.product-outofstock-popup__container').addClass('hide');
    });

    $('button.notify-me').click(function () {
        $('.product-outofstock-popup__container').removeClass('hide');
    });
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            $('#quickViewModal .modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .quickview.read-more, #quickViewModal .quickview-modal-product-name').attr('href', data.productUrl);
            $('#quickViewModal .quickview.read-more').on('click', function (e) {
                e.stopPropagation();
            });
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal').modal('show');
            renderPdpContentAssets();
            togglePopup();
            initProductCarousel();
            window.setWishlistState && window.setWishlistState(data.product.id);
        }
    });
}

function showAfterSubmitPopup() {
    $('.product-outofstock-popup-before-submit').addClass('hide');
    $('.product-outofstock-popup-after-submit').removeClass('hide');
}

/**
 * @private
 * @function
 * @description Submit an email on Back in Stock Popup
 */

function submitBackInStock() {
    var emailInput = $('.product-outofstock-popup-email-form-input').val();
    var pid = $('.product-outofstock-popup-pid-form-input').val();

    var regExp = /^[a-zA-Z0-9]*([\w+-]+\.)*[\w+-]+@[a-zA-Z0-9]([\w-]+\.)*[a-zA-Z]{2,6}$/;
    var error = '';

    if (emailInput.length == 0) {
        error = 'this field is required';
    } else if (!emailInput.match(regExp)) {
        error = 'please enter a valid email';
    }
    if (!error && error.length == 0) {
        grecaptcha.ready(function () { // eslint-disable-line
            grecaptcha.execute($('.product-outofstock-popup-submit-quick-view').attr('data-recaptcha-site-key'), { action: 'backinstock' }).then(function (token) { // eslint-disable-line
                var params = {
                    email: emailInput,
                    pid: pid,
                    token: token,
                    action: 'backinstock'
                };

                var url = $('.product-outofstock-popup-submit-quick-view').data('url');

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: params,
                    success: function () {
                        $('.product-outofstock-popup-email-submit').html(emailInput);
                        showAfterSubmitPopup();
                        $('.back-in-stock__success').removeClass('hide');
                    }
                });
            });
        });
    } else {
    // Email is empty or not valid - Show error
        $('.back-in-stock__error').removeClass('hide');
        $('.back-in-stock__error').text(error);
    }
}

function quantumPriceUpdate(tilesArray, tileInArray, productQuantity, quickviewOpen) {
    var pricePerProduct = tilesArray[tileInArray].attributes['data-price'].value;
    var percentagae = tilesArray[tileInArray].attributes['data-percentage'].value;
    var currency = tilesArray[tileInArray].attributes['data-currency'].value;
    var newPrice = productQuantity * (pricePerProduct * (1 - percentagae / 100)).toFixed(2);

    if (quickviewOpen) {
        $('.quickview-modal .prices-and-availability .price-sales').text(currency == '€' ? newPrice.toFixed(2) + ' ' + currency : Math.round(newPrice) + ' ' + currency);
    } else {
        $('.prices-and-availability .price-sales').text(currency == '€' ? newPrice.toFixed(2) + ' ' + currency : Math.round(newPrice) + ' ' + currency);
    }
}

function quantumUpdate(productQuantity) {
    var quickviewOpen = $('.quickview-modal').hasClass('show');
    var tilesArray = $('.promotion-box-tiles').find('.promotion-box-tile');
    var tilesLength = tilesArray.length;

    // Start from the highest tile so we don't duplicate calculations
    for (var i = tilesLength - 1; i >= 0; i--) {
        var tileQuantity = parseInt(tilesArray[i].getAttribute('data-quantity'));

        if (productQuantity >= tileQuantity) {
            updateTile(i, quickviewOpen);
            quantumPriceUpdate(tilesArray, i, productQuantity, quickviewOpen);
            break;
        }
    }
}

function updateTile(index, quickviewOpen) {
    var selector = quickviewOpen ? '.quickview-modal' : '';
    $(selector + ' .promotion-box-tile').removeClass('active');
    $(selector + ' .promotion-box-tiles > .promotion-box-tile:nth-child(' + (index + 1) + ')').addClass('active');
}


quickView.showQuickview = function () {
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var selectedValueUrl = $(this).closest('a.quickview').attr('data-href');
        $(e.target).trigger('quickview:show');
        getModalHtmlElement();
        fillModalElement(selectedValueUrl);
    });
};
if (FeatureFlagRegistrar.isSite('GG')) {
    quickView.hideDialog = function () {
        $('body').on('product:afterAddToCart', function () {
            if ($('#quickViewModal').length > 0) {
                $('#quickViewModal').modal('hide');
            }
        });
    };
}


// Do not duplicate callbacks already present in detail.js

// This function is migrated from detail.js script to avoid mess with duplicated callbacks.
// Quick view is loaded on all pages, while defining it in detail.js does not make it working on Quick View popup.
quickView.updateQuantity = function () {
    $('body').on('click', '[data-animation]', function (e) {
        e.preventDefault();
        var productQuantityInput = $(this).siblings('.quantity-select');
        var quantity = productQuantityInput.val();
        var minOrderQuantity = parseInt(productQuantityInput.data('min-quantity')) || 1;
        var stepOrderQuantity = parseInt(productQuantityInput.data('step-quantity')) || 1;
        var productQuantity = parseInt(quantity);

        if ($(this).hasClass('increase-quantity')) {
            var newProductQuantity = productQuantity + stepOrderQuantity;

            productQuantityInput.val(newProductQuantity);

            if (productQuantity == minOrderQuantity) {
                $(this).siblings('.icon--disabled').removeClass('icon--disabled');
            }

            if ($('.quantum').length) {
                quantumUpdate(newProductQuantity);
            }
        } else if ($(this).hasClass('decrease-quantity') && quantity >= minOrderQuantity + stepOrderQuantity) {
            var newSubtractedQuantity = productQuantity - stepOrderQuantity;

            productQuantityInput.val(newSubtractedQuantity);

            if (newSubtractedQuantity == minOrderQuantity) {
                $(this).addClass('icon--disabled');
            }

            if ($('.quantum').length) {
                quantumUpdate(newSubtractedQuantity);
            }
        }

        var parentElement = $(this).parents('.quantity-and-add-to-cart');
        var trackingPayload = parentElement ? parentElement.find('button.add-to-cart')[0] : undefined;
        var trackingEventPayload = trackingPayload ? JSON.parse(trackingPayload.dataset.trackingEventPayload) : undefined;
        if (trackingEventPayload) {
            trackingEventPayload.add.products[0].quantity = parseInt(productQuantityInput.val());
            trackingPayload.dataset.trackingEventPayload = JSON.stringify(trackingEventPayload);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var userInputQuantity = parseInt($(this).val());
        var minOrderQuantity = parseInt($(this).data('min-quantity')) || 1;
        var stepOrderQuantity = parseInt($(this).data('step-quantity')) || 1;
        var updatedQuantity;

        if (userInputQuantity <= minOrderQuantity) {
            updatedQuantity = minOrderQuantity;
            $(this).siblings('.decrease-quantity').addClass('icon--disabled');
        } else if (stepOrderQuantity > 1) {
            if (userInputQuantity < minOrderQuantity + stepOrderQuantity) {
                updatedQuantity = minOrderQuantity;
            } else {
                var excessQuantity = (userInputQuantity - minOrderQuantity) % stepOrderQuantity;
                updatedQuantity = userInputQuantity - excessQuantity;
            }
        } else {
            updatedQuantity = userInputQuantity;
        }

        $(this).val(updatedQuantity);

        if ($('.quantum').length) {
            quantumUpdate(updatedQuantity);
        }
    });
};

// The same as with "quickView.updateQuantity" above - migrated from detail.js to fix duplicated callbacks.
quickView.showPreMinicart = function () {
    $('body').on('product:afterAddToCart', function (e, data) {
        if (data.productIsAdded) {
            showPreMinicartBox(data);
        }
        if (data.cart.items.length > 0) {
            showCartItemsHeader();
        }
    });
};

// Do not export remove "addToCart" from being exported there. It's used for callbacks on both PDP and Quick View.
quickView.addToCart = base.addToCart;
quickView.availability = base.availability;

quickView.colorAttribute = base.colorAttribute;
quickView.selectAttribute = base.selectAttribute;
quickView.removeBonusProduct = base.removeBonusProduct;
quickView.selectBonusProduct = base.selectBonusProduct;
quickView.enableBonusProductSelection = base.enableBonusProductSelection;
quickView.showMoreBonusProducts = base.showMoreBonusProducts;
quickView.addBonusProductsToCart = base.addBonusProductsToCart;

quickView.focusChooseBonusProductModal = base.focusChooseBonusProductModal();

module.exports = quickView;
