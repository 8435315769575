'use strict';

module.exports = function () {
    $('[data-copy-to-clipboard]').click(function () {
        var clipboardValue = $(this).siblings('[data-clipboard]').val();
        var clipboardTextToCopy = $('[data-id=' + clipboardValue + ']');
        clipboardTextToCopy.focus();
        clipboardTextToCopy.select();
        document.execCommand('copy');
        $(this).parent().find('.copy-to-clipboard-message').show().delay(2000).fadeOut('fast');
    });
};
