/* eslint-disable */

'use strict';

module.exports = function () {
    // Passive event listeners
    // const passive = {
    //     passive: true
    // };
    //
    // function passiveHandler(event) {
    //     event.preventDefault();
    // }

    // Atm the reason of those event listeners is unclear, commenting out.
    // document.addEventListener('touchstart', passiveHandler, passive);
    // document.addEventListener('touchmove', passiveHandler, passive);
};

