'use strict';

import { getLogger } from 'shared/js/dev-mode';
const logger = getLogger();
import _ from 'shared/js/underscore';

/* eslint-disable no-bitwise */

const CART_MODEL_FIELDS = [
    'actionUrls',
    'approachingDiscounts',
    'hasBonusProduct',
    'items',
    'numItems',
    'numOfShipments',
    'resources',
    'shipments',
    'totals',
    'valid',
    // PLEDGE FIELDS - START
    // Pledge fields have to be added to the listing of allowed ones since
    // that data model is send to CartRenderer-RenderCart, where that value is used to
    // render pledge-specific templates' fragments.
    'subTotalWithoutPledge',
    'subTotalBasePriceBasedWithoutPledge',
    'totalPledgeValue',
    'totalPledgeValueExclTax',
    'grandTotalWithPledge',
    'grandTotalWithoutPledge',
    'grandTotalWithPledgeRaw',
    'grandTotalWithoutPledgeRaw',
    'pledgeConfig'
    // PLEDGE FIELDS - END
];

const CART_MODEL_TOTAL_FIELDS = [
    'adjustedMerchandizeTotalGrossPrice',
    'adjustedMerchandizeTotalGrossPriceRaw'
];

// const CART_MODEL_DATA_FIELDS = [
//     'items',
//     'shipments',
//     'totalDiscount',
//     'totals'
// ];

module.exports = {
    /**
     * Determines if a string could be parsed as a
     * JSON.
     * @param {string} str
     *
     * @return {boolean}
     */
    isJSON: function (str) {
        try {
            return JSON.parse(str) && !!str;
        } catch (e) {
            return false;
        }
    },

    /**
     * Determines if object is cartModel. So far, the definitions
     * of this is that all cartModel top-level-fields need to be present.
     *
     * @param {object} cartModel
     *
     * @return {boolean}
     */
    isCartModel: function (cartModel) {
        try {
            const cartHasAllProperties = CART_MODEL_FIELDS.every((key) => cartModel.hasOwnProperty(key));
            const totalsHaveAllProperties = CART_MODEL_TOTAL_FIELDS.every((key) => cartModel.totals.hasOwnProperty(key));
            return cartHasAllProperties && totalsHaveAllProperties;
        } catch (e) {
            return false;
        }
    },

    getCartSkeleton: function (cartModel) {
        let cartSkeleton = {};
        try {
            cartSkeleton = {
                items: cartModel.items.map(function (item) {
                    return {
                        UUID: item.UUID,
                        quantity: item.quantity,
                        priceTotal: item.priceTotal.rawPrice
                    };
                }),
                totals: {
                    orderLevelDiscountTotal: cartModel.totals.orderLevelDiscountTotal.value,
                    productLevelDiscount: cartModel.totals.productLevelDiscount.value,
                    grandTotal: this.getBasketGrandTotal(cartModel),
                    subTotal: this.getBasketSubTotal(cartModel)
                }
            };
        } catch (e) {
            // errors are thrown in case cartModel does not exist. This is expected
            logger.error('getCartSkeleton', e);
        }

        return cartSkeleton;
    },

    /**
     * Filter out invasive data from, supposed, cartModel objects.
     *
     * @param {object} cartModel
     *
     * @return {object}
     */
    filterCartModelObject: function (cartModel) {
        return _.pick(cartModel, CART_MODEL_FIELDS);
    },

    /**
     * Get an object containing the differences between two objects.
     * Will return null in case no differences.
     *
     * (functions not accounted for)
     *
     * @param {object} objectA
     * @param {object} objectB
     * @param optional {string} key
     *
     * @return {object|false}
     */
    objectDifference: function (objectA, objectB, key) {
        var self = this;

        var res = {};
        // Get the object type
        var typeA = Object.prototype.toString.call(objectA);
        var typeB = Object.prototype.toString.call(objectB);

        if (typeA !== typeB) {
            if (!key) {
                // This state should only be possible on first input (as opposed
                // to recursive runs from inside this function) where two different
                // types of data has been supplied. eg. Object and Null
                return typeB == '[object Object]' ? objectB : true;
            }
            res[key] = objectB;
        } else if (typeA == '[object Object]') {
            // Inspecting for elements that might have been removed from
            // objectA
            Object.keys(objectA).forEach(function (_key) {
                if (Object.keys(objectB).indexOf(_key) === -1) {
                    res[_key] = null;
                } else {
                    var diff = self.objectDifference(objectA[_key], objectB[_key], _key);
                    if (diff) {
                        res[_key] = diff;
                    }
                }
            });
            // Inspecting for elements that have been added to objectB
            Object.keys(objectB).forEach(function (_key) {
                if (Object.keys(objectA).indexOf(_key) === -1) {
                    res[_key] = objectB[_key];
                }
            });
        } else if (typeA == '[object Array]') {
            // It's not really possible to say if there has been changes to
            // assuming that any change could cause the array to end up in
            // different order
            objectA.sort();
            objectB.sort();
            if (objectA.length !== objectB.length || JSON.stringify(objectA) != JSON.stringify(objectB)) {
                res[key] = objectB;
            }
        } else if (objectA != objectB) {
            res[key] = objectB;
        }

        return Object.keys(res).length > 0 ? res : false;
    },

    getCookieByName: function (name) {
    // After compilation it will be transformed to:
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
        return 'n/a';
    },

    getSessionID: function () {
        return this.getCookieByName('sid');
    },

    isEqual: function (objectA, objectB) {
        var diff = this.objectDifference(objectA, objectB);
        logger.log('isEqual (objectDifference)', diff);
        return !this.objectDifference(objectA, objectB);
    },

    // https://gist.github.com/iperelivskiy/4110988
    hash: function (s) {
    /* Simple hash function. */
        var a = 1,
            c = 0,
            h,
            o;
        if (s) {
            a = 0;
            /* jshint plusplus:false bitwise:false*/
            for (h = s.length - 1; h >= 0; h--) {
                o = s.charCodeAt(h);
                a = (a << 6 & 268435455) + o + (o << 14);
                c = a & 266338304;
                a = c !== 0 ? a ^ c >> 21 : a;
            }
        }
        return String(a);
    },
    getBasketGrandTotal: function (cartModel) {
        try {
            return Math.round(parseFloat(cartModel.totals.grandRawTotal) * 100);
        } catch (e) {
            return 0;
        }
    },
    getBasketSubTotal: function (cartModel) {
        try {
            return cartModel.totals.subTotal;
        } catch (e) {
            return '0';
        }
    },
};
