'use strict';

// This file is copied from BS, styling flags are adjusted for version from: GG and PF

/* eslint-disable indent */

import FeatureFlagRegistrar from 'shared/js/featureFlagRegistrar';

module.exports = {
    init: function () {
        var self = this;
        // Initiating central urls
        self.containerElement = $('#klarna-instant-shopping-button-widget');
        self.buttonKey = self.containerElement.data('button-key');
        self.environment = self.containerElement.data('environment');
        self.purchaseCountry = self.containerElement.data('purchase-country');
        self.purchaseCurrency = self.containerElement.data('purchase-currency');
        self.locale = self.containerElement.data('locale');
        self.terms = self.containerElement.data('terms');
        self.reference = self.containerElement.data('reference');
        self.name = self.containerElement.data('name');
        self.productURL = self.containerElement.data('product-url');
        self.imageURL = self.containerElement.data('image-url');
        self.unitPrice = self.containerElement.data('unit-price');

        return self;
    },
    loadCallback: function () {
        var self = this;

        window.klarnaAsyncCallback = function () {
            Klarna.InstantShopping.load({ //eslint-disable-line
                setup: {
                    instance_id: 'klarna-pdp-button',
                    key: self.buttonKey,
                    environment: self.environment,
                    region: 'eu'
                },
                purchase_country: self.purchaseCountry,
                purchase_currency: self.purchaseCurrency,
                locale: self.locale,
                merchant_urls: {
                    terms: self.terms
                },
                order_lines: [{
                    type: 'physical',
                    reference: self.reference,
                    name: self.name,
                    quantity: 1,
                    unit_price: parseInt(self.unitPrice),
                    tax_rate: 0,
                    total_amount: parseInt(self.unitPrice),
                    total_discount_amount: 0,
                    total_tax_amount: 0,
                    product_url: self.productURL,
                    image_url: self.imageURL
                }],
                styling: {
                    theme: {
                        // #CDIFF - #DONE need to be refactored into configurable option
                        // - GG: klarna + dark
                        // - PF: klarna + dark
                        // - BS: light + express
                        variation: FeatureFlagRegistrar.get('checkout.klarna.instantshopping.style.variation', 'light'),
                        type: FeatureFlagRegistrar.get('checkout.klarna.instantshopping.style.type', 'express')
                    }
                }
                }, function (response) {
                    console.log('Klarna.InstantShopping.load callback with data:' + JSON.stringify(response)); //eslint-disable-line
                });
            };
        },
    update: function (data) {
        var self = this;

        Klarna.InstantShopping.update({ //eslint-disable-line
            setup: {
                instance_id: 'klarna-pdp-button',
            },
            order_lines: [{
                type: 'physical',
                reference: data.product.id,
                name: data.product.productName,
                quantity: 1,
                unit_price: parseInt(data.product.price.sales.value) * 100,
                tax_rate: 0,
                total_amount: parseInt(data.product.price.sales.value) * 100,
                total_discount_amount: 0,
                total_tax_amount: 0,
                product_url: self.productURL,
                image_url: data.product.images.large.length ? data.product.images.large[0].url : null
            }]
        }, function (response) {
            console.log('Klarna.InstantShopping.load callback with data:' + JSON.stringify(response)); //eslint-disable-line
        });
    }
};

if (!FeatureFlagRegistrar.get('checkout.klarna.active')
    || !FeatureFlagRegistrar.get('checkout.klarna.instantshopping.active')) {
    module.exports = {};
}
