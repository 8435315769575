'use strict';

export function aggregateFlagsOnNode(element) {
    let container = {};
    if (!element || !element.nodeType) {
        return container;
    }
    for (let i = 0, attributes = element.attributes, length = attributes.length; i < length; i++) {
        const name = attributes[i].nodeName;
        if (name.indexOf('data-request-flag') > -1) {
            container[name.substring(18)] = attributes[i].nodeValue;
        }
    }
    return container;
}


export default { aggregateFlagsOnNode };
