'use strict';

import FeatureFlagProvider from './featureFlag/provider';

// Do not wait for shared components being fully loaded, because configuration requires
// immediate availability.
// document.addEventListener('custom:shared-components-loaded', () => {
let providers = [];
const collectConfigProviderEvent = new CustomEvent(
    'integration:feature-flag-provider',
    {
        detail: {
            registrar: FeatureFlagProvider,
            providers: providers
        }
    }
);
document.dispatchEvent(collectConfigProviderEvent);
providers.forEach(config => FeatureFlagProvider.register(config));
// });

export default FeatureFlagProvider;
