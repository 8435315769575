'use strict';

module.exports = function () {
    $('body').on('click', '[data-dropdown-header]', function (e) {
        e.preventDefault();
        $(this).closest('[data-dropdown]').toggleClass('active');
        e.stopPropagation();
    });

    $(document).on('click', function (e) {
        if ($(e.target).is('[data-dropdown]') === false) {
            $('[data-dropdown]').removeClass('active');
        }
    });
};
