'use strict';

import _ from 'shared/js/underscore';

export function trimSlashes(url) {
    return url ? url.replace(/(^\/)|(\/$)/g, '') : '';
}

export function isNotVanityPath(path) {
    return path.indexOf('.demandware.') > -1 || path.indexOf('.commercecloud.') > -1;
}
export function isVanityUrl(url) {
    const activeUrl = url || (new URL(document.URL)).hostname;
    return !isNotVanityPath(activeUrl);
}

export function getControllerRootUrl() {
    const activeUrl = new URL(document.URL);
    let result = `${activeUrl.protocol}//${activeUrl.hostname}`;
    if (activeUrl.pathname.indexOf('demandware.store') > -1) {
    // Use the next 4 segments,
    // ex. "/on/demandware.store/Sites-Bodystore-Site/sv_SE"
        result += `/${trimSlashes(activeUrl.pathname).split('/').slice(0, 4).join('/')}/`;
        _.some([window.currentLocale, window.locale], (lc) => {
            if (result.indexOf(`/${lc}`) === -1) {
                result += `${lc}/`;
                return true;
            }
        });
    }
    return trimSlashes(result);
}

export function getControllerAlias(path) {
    path = trimSlashes(path);
    path = window._csBootstrap && window._csBootstrap.urls
        ? (window._csBootstrap.urls[path] || path)
        : path;
    return trimSlashes(path);
}

/**
 * Resolves the controller 2x parts fragment from the currently opened URL (of the page).
 * @param {string} [url] - URL string otherwise will be taken document.URL
 * @return {string} the Controller-Name reference
 * @example Home-Show | Product-Show
 */
export function getControllerPart(url) {
    const root = getControllerRootUrl();
    const activeUrl = (new URL(url || document.URL)).toString();
    const index = activeUrl.indexOf(root);
    if (index > -1) {
        let part = trimSlashes(activeUrl.substring(index + root.length)).split('?')[0];
        if (window._csBootstrap && window._csBootstrap.urls) {
            const alias = _.findKey(window._csBootstrap.urls, (value) => value === part);
            if (alias) {
                part = alias;
            }
        }
        return part || 'Home-Show';
    }
    return 'Home-Show';
}

/**
 *
 * @param {string} path Path to the controller. It can be either relative URL, 2x parts controller or absolute URL.
 * @return {string} Returns FQ path to the destination endpoint (absolute URL).
 */
export function getControllerUrl(path) {
    path = getControllerAlias(path);
    // Otherwise fallback to regular full URL.
    if (path.indexOf('demandware.store') > -1) {
        path = trimSlashes(path);
        const cp = getControllerRootUrl();
        if (path.indexOf('http') === 0) {
            path = path.replace(cp, '');
        } else {
            const cpUrl = new URL(cp);
            const cpPart = trimSlashes(cpUrl.pathname);
            path = path.replace(cpPart, '');
        }
    }
    return `${getControllerRootUrl()}/${trimSlashes(path)}`;
}

export function addQueryToUrl(url, append) {
    if (url && url[0] === '/') {
        url = getControllerUrl(trimSlashes(url));
    }
    let result = new URL(url);
    let params = result.searchParams;

    _.each(append, (value, key) => params.append(key, value));
    return result;
}

export function getSiteName() {
    let result;
    if (window.hasOwnProperty('staticURL')) {
        try {
            result = window.staticURL.split('/')[3].split('-')[1];
        } catch (exception) {}
    }
    if (!result) {
        const root = getControllerRootUrl();
        result = root.split('/').splice(-2)[0].split('-')[1];
    }
    return result;
}

export default {
    addQueryToUrl,
    isVanityUrl,
    isNotVanityPath,
    getControllerRootUrl,
    getControllerUrl,
    getControllerPart,
    getSiteName
};
