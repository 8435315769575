'use strict';

var previousScroll = 0;

module.exports = function () {
    // Grab the html of the <isinclude template="components/header/menuIcons"/>
    // include and inject it into the .header-main (the scroll header)
    // This is to get around the below remote include that have been loading twice each page load:
    // <isinclude url="${URLUtils.url('Account-Header')}" />
    // <isinclude url="${URLUtils.url('Cart-MiniCart')}" />
    var iconsHtml = $('.header-top .header-menu-icons').html();
    $('.header-main .header-menu-icons').html(iconsHtml);

    $(window).on('scroll', function () {
        var currentScroll = $(this).scrollTop();
        if (currentScroll > previousScroll && $(window).scrollTop() > 200) {
            $('.header').addClass('scroll-down');
        } else {
            $('.header').removeClass('scroll-down');
        }

        if (currentScroll > 200) {
            $('.header').addClass('sticky');
        } else {
            $('.header').removeClass('sticky');
        }

        previousScroll = currentScroll;
    });
};
