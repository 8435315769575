'use strict';

var util = {
    /**
     * @function
     * @description
     * @param {string} el first Param
     * @param {string}  offsetToTop second Param
     * @returns {string} string
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            var ele = el.offsetParent;
            top += ele.offsetTop;
            left += ele.offsetLeft;
        }

        if (typeof offsetToTop !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return top < window.pageYOffset + window.innerHeight && left < window.pageXOffset + window.innerWidth && top + height > window.pageYOffset && left + width > window.pageXOffset;
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < window.document.documentElement.scrollTop + window.document.documentElement.clientHeight &&
                left < window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth &&
                top + height > window.document.documentElement.scrollTop &&
                left + width > window.document.documentElement.scrollLeft
            );
        }
        return null;
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {string} path the relative path
     * @return {string} path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {string} url firsts param
     * @return {string} url
     */
    toAbsoluteUrl: function (url) {
        let link;
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            link = '/' + url;
        }
        return link;
    },

    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {string} xLocation first param
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({ scrollTop: xLocation }, 500);
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = navigator.userAgent.toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = userAgent.indexOf(mobileAgentHash[idx]) >= 0;
            idx++;
        }
        return isMobile;
    },

    /**
     * Get Screen Size from media queries
     *
     * @return {string} return screen size
     */
    getScreenSize: function () {
        var screenSize = '';

        if ($('.screen-size-indicator').length > 0) {
            screenSize = window.getComputedStyle($('.screen-size-indicator')[0], ':before').getPropertyValue('content');
            screenSize = screenSize.replace(/"/g, '');
        }

        return screenSize;
    }
};

module.exports = util;
