module.exports = function () {
    $('.unfold-link').click(function (e) {
        e.preventDefault();
        $(this).css('display', 'none');
        $(this).siblings('.fold-link').css('display', 'block');
        var unfoldableElements = $(this).parent().find('.unfoldable');
        unfoldableElements.removeClass('unfoldable');
        unfoldableElements.addClass('foldable');
    });

    $('.fold-link').click(function (e) {
        e.preventDefault();
        $(this).css('display', 'none');
        $(this).siblings('.unfold-link').css('display', 'block');
        var unfoldableElements = $(this).parent().find('.foldable');
        unfoldableElements.removeClass('foldable');
        unfoldableElements.addClass('unfoldable');
    });
};
