'use strict';

import { getCookieByName } from './util/cookies-operation';

export function isDevMode() {
    const value = getCookieByName('public.dev.investigationMode');
    return value && (['0', 0, 'off', false, 'false', ''].indexOf(value) === -1);
}

export function getLogger() {
    if (isDevMode()) {
        return console;
    }
    const dummy = () => void 0; /* eslint-disable-line no-void */
    return {
        debug: dummy,
        error: dummy,
        info: dummy,
        log: dummy,
        warn: dummy,
    };
}

export default {
    isDevMode,
    getLogger
};
