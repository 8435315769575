/* jshint esversion: 6 */

'use strict';

import _ from './underscore/lib';
import objects from './underscore/objects';
import strings from './underscore/string';

let underscore = _.mixin(objects(_));
underscore = _.mixin(strings.exports());
export default underscore;
