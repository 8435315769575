'use strict';

document.addEventListener('integration:feature-flag-provider', (event) => {
    event.detail.registrar.register({
        site: 'GG',
        'analytics.gtm.active': true,
        'voyado.tracker.active': true,
        'cart.active': true,
        'checkout.klarna.active': true,
        'checkout.klarna.instantshopping.active': false,
        // klarna + dark OR express + light
        'checkout.klarna.instantshopping.style.variation': 'light',
        'checkout.klarna.instantshopping.style.type': 'express',
        'customer.registration.enabled': true,
        'customer.registration.registrationTabFlag': true,
        'integration.gmf.active': true
    });
});
