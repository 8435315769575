'use strict';

import _ from 'shared/js/underscore';

class Provider {
    #config = {};
    #callbacks = [];

    /**
     * @param {Object} config
     * @return {void}
     */
    register(config) {
        config = _.unflatten(config);
        this.#config = _.extend(this.#config, config);
    }

    /**
     * @param {Function} callback
     */
    callback(callback) {
        if (typeof callback === 'function') {
            this.#callbacks.push(callback);
        }
    }

    /**
     * @param {Array|String} key
     * @param {*} [defaults]
     * @return {*}
     */
    get(key, defaults) {
        key = _.isArray(key) ? key : key.split('.');
        return _.get(this.#config, key, defaults);
    }

    /**
     * @param {String|String[]} site
     * @returns {Boolean}
     */
    isSite(site) {
        site = arguments.length > 1 ? [...arguments] : site;
        if (!_.isArray(site)) {
            site = [site];
        }
        const currentSite = this.getCurrentSite();
        return site.some(
            (cValue) => currentSite === cValue.toUpperCase()
        );
    }

    /**
     * Returns ID of the current site.
     *
     * @return {string}
     */
    getCurrentSite() {
        return this.get('site', '').toUpperCase();
    }

    /**
     * @returns {Function[]}
     */
    getCallbacks() {
        return this.#callbacks;
    }
}

export default new Provider();
