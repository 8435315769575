'use strict';

module.exports = function () {
    $('body').on('click', '[data-tab-header]', function (e) {
        e.preventDefault();

        if (!$(this).hasClass('active')) {
            // Remove class Active from other Tabs
            $('[data-tab-header]').removeClass('active');
            $('[data-tab-content]').removeClass('active');

            // Set Tab Header Item as Active
            $(this).addClass('active');

            // Set Tab Content Item as Active
            var clickedTab = $(this).data('tab-header');
            $('[data-tab-header=' + clickedTab + ']').addClass('active');
            $('[data-tab-content=' + clickedTab + ']').addClass('active');
        }
    });
};
