'use strict';

export function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

/**
 * Get specific cookie's value by its name.
 *
 * @param {string} name
 * @param {boolean} [decode=false]
 * @return {string|undefined}
 */
export function getCookieByName(name, decode) {
    decode = typeof decode === 'undefined' ? false : !!decode;
    // After compilation, it will be transformed to:
    // var value = "; ".concat(document.cookie);
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        let cookieValue = parts.pop().split(';').shift();
        return decode ? b64DecodeUnicode(cookieValue) : cookieValue;
    }
    return undefined;
}
