'use strict';

module.exports = function (element) {
    // #CDIFF #TBD unknown why "-400", SFRA version does not have it
    var position = element && element.length ? element.offset().top - 400 : 0;
    $('html, body').animate({
        scrollTop: position
    }, 500);

    if (!element) {
        $('.logo-home').focus();
    }
};
