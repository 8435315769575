// noinspection DuplicatedCode

'use strict';

/**
 * Sets nested property of a specified object.
 *
 * @param {Object} parent - Object to look inside for the properties.
 * @param {Array} path - Split path the property.
 * @param {*} value - Value of the last property in 'path' array.
 * @returns {*} New value for the property.
 */
function setNested(parent, path, value) {
    let last = path.pop(),
        len = path.length,
        pi = 0,
        part = path[pi];

    for (; pi < len; part = path[++pi]) {
        const type = typeof parent[part];
        if (!(type === 'function' || type === 'object' && !!parent[part])) {
            parent[part] = {};
        }

        parent = parent[part];
    }

    if (typeof parent[last] === 'function') {
        parent[last](value);
    } else {
        parent[last] = value;
    }

    return value;
}

const exports = (_) => {
    return {
    /**
         * Opposite operation of the 'flatten' method.
         *
         * @param {Object} data - Previously flattened object.
         * @param {String} [separator='.'] - Keys separator.
         * @returns {Object} Object with nested properties.
         *
         * @example Example using custom separator.
         *      _.unflatten({'one=>two': 'value'}, '=>');
         *      => {
         *          one: { two: 'value' }
         *      };
         */
        unflatten: (data, separator) => {
            let result = {};

            separator = separator || '.';

            _.each(data, function (value, nodes) {
                nodes = nodes.split(separator);

                setNested(result, nodes, value);
            });

            return result;
        },
        unset: (subject, path) => { // NOSONAR
            let pathArray = !Array.isArray(path) ? [path] : path;
            const field = pathArray.pop();

            let parent = _.get(subject, pathArray);
            if (parent.hasOwnProperty(field)) {
                delete parent[field];
            }
            return subject;
        },
        set: setNested,
        merge: (target, source, replaceOnLevel) => {
            let _replaceOnLevel,
                _currentLevel = 0;
            if (_.isNumber(replaceOnLevel)) {
                _replaceOnLevel = replaceOnLevel;
            } else if (_.isArray(replaceOnLevel)) {
                _replaceOnLevel = replaceOnLevel[0];
                _currentLevel = replaceOnLevel[1];
            }
            _.each(source, function (value, key) {
                if (!_.isUndefined(target[key]) && !(typeof _replaceOnLevel === 'number' && _currentLevel === _replaceOnLevel)) {
                    if (!_.isArray(target[key]) && !_.isObject(target[key])) {
                        if (_.isNumber(key) && _.isArray(target)) {
                            target.push(value);
                            target = _.uniq(target);
                        } else {
                            target[key] = value;
                        }
                    } else if (_.keys(target[key]) === _.range(0, _.keys(target[key]).length - 1)) {
                        target[key] = _.uniq(_.extend(target[key], value)); // Check - maybe needed recursive call there.
                    } else {
                        target[key] = _.merge(target[key], value);
                    }
                } else {
                    target[key] = value;
                }
            });
            // TODO Remove properties not more present in object.
            return target;
        }
    };
};

export default (_) => exports(_);
