'use strict';

// Not SFRA 6x script upgrade compatible.Selective changes applied.

var states = require('improove/util/states');
var util = require('improove/util/util');
var ld = require('lodash');

import FeatureFlagRegistrar from 'shared/js/featureFlagRegistrar';

/**
 * Open the level 2 menu and mark the active menu if we are in PLP.
 *
 * @returns {void}
 */
function openCurrentCategory() {
    var currentCategoryPath = document.querySelector('.js_current-category-path');
    if (currentCategoryPath && currentCategoryPath.dataset.currentCategoryPath !== 'null') {
        var path = JSON.parse(currentCategoryPath.dataset.currentCategoryPath);
        var selector = path.reduce((result, item) => {
            return (result && (result + ', ')) + '.menu-level-1-item[data-category-id="' + item + '"], .menu-level-2-item[data-category-id="' + item + '"]';
        }, '');
        var items = document.querySelectorAll(selector);
        for (let i = 0; i < items.length; i++) {
            items[i].classList.add('selected');
        }
        var notSelected = document.querySelectorAll('.menu-level-1-item:not(.selected), .menu-level-2-item:not(.selected)');
        for (let i = 0; i < notSelected.length; i++) {
            notSelected[i].classList.add('not-selected');
        }
    }
}

// Reset Menu Method
var resetMenu = function () {
    var containers = $('.menu-level-2-container, .menu-level-3-container');
    containers.each(function (i, el) {
        if ($(el).hasClass('menu-active-container')) {
            $(el).removeClass('menu-active-container');
        }
    });
    $('.menu-level-1-container').removeClass('level-2-active level-3-active');
    $('.menu-back-action').removeClass('show-button');
    $('.menu-level-1-item').unbind('mouseenter');
    $('.menu-level-1-item').unbind('mouseleave');
    $('.menu-level-3-item').unbind('click');
    if (!FeatureFlagRegistrar.isSite('FM')) {
        $('.menu-level-1-item').unbind('click'); // #CDIFF #DONE FM does not have that line
    }
    $('.menu-level-2-item').unbind('click');
    $('.menu-back-action').unbind('click');
    $('.menu-parent-category').unbind('click');
    if (!FeatureFlagRegistrar.isSite('FM', 'BS')) {
        $('.menu-parent-category-se').unbind('click'); // BS, FM don't have that line
    }
};

var menu = {};
menu.init = function init() {
    states.add('menu', $('.state-container--menu'), $('.menu-state-trigger'), {
        onActivate: function () {
            $('.menu-main-container').addClass('active');
        },
        onDeactivate: function () {
            $('.menu-main-container').removeClass('active');
            resetMenu();
        }
    });

    function loadMenu() {
        resetMenu();
        if (util.getScreenSize() === 'small' || util.getScreenSize() === 'medium') {
            // Go to Sub menu level 2
            if ($('body').hasClass('state--menu')) {
                $('.menu-main-container').addClass('active');
            }

            $('.menu-level-1-item').on('click', function (e) {
                if ($(this).find('div').hasClass('menu-level-2-container')) {
                    e.preventDefault();
                    $('.menu-level-1-container').addClass('level-2-active');
                    $(this).find('.menu-level-2-container').addClass('menu-active-container');
                    $('.menu-back-action').addClass('show-button');
                }
            });
            // Go to Sub menu Level 3
            $('.menu-level-2-item').on('click', function (e) {
                if ($(this).find('div').hasClass('menu-level-3-container')) {
                    e.preventDefault();
                    $('.menu-level-1-container').addClass('level-3-active');
                    $(this).find('.menu-level-3-container').addClass('menu-active-container');
                    $('#menu-state-container').scrollTop(0);
                } else {
                    e.stopPropagation();
                }
            });

            // Stop Propagation level 3 and for campaign pages in menu list
            $('.menu-level-3-item, .menu-content-list').on('click', function (e) {
                e.stopPropagation();
            });

            // Button go back on Sub menu
            $('.menu-back-action').on('click', function () {
                let level1c = $('.menu-level-1-container');
                if (level1c.hasClass('level-3-active')) {
                    level1c.removeClass('level-3-active');
                    $('.menu-level-3-container').removeClass('menu-active-container');
                } else if (level1c.hasClass('level-2-active')) {
                    level1c.removeClass('level-2-active');
                    $('.menu-level-2-container').removeClass('menu-active-container');
                    $('.menu-back-action').removeClass('show-button');
                }
            });

            // #CDIFF #DONE GG, PF has line instead:
            var selectorMenuParentCategory;
            if (FeatureFlagRegistrar.isSite('GG', 'PF')) {
                selectorMenuParentCategory = '.menu-parent-category, .menu-parent-category-se';
            } else {
                selectorMenuParentCategory = '.menu-parent-category';
            }
            $(selectorMenuParentCategory).on('click', function (event) {
                event.stopPropagation();
            });
        } else if (
            (FeatureFlagRegistrar.isSite('BS', 'SN') && util.getScreenSize() === 'large')
            || (FeatureFlagRegistrar.isSite('FM', 'GG', 'PF') && util.getScreenSize() !== 'small')
        ) { // #CDIFF #DONE FM, GG, PF have !== 'small' instead
            // Only for Desktop
            $('.menu-level-1-item')
                .on('mouseenter', function () {
                    $('.menu-main-container').addClass('active');
                    // FM, GG, PF has $(this).addClass('active');
                    if (
                        $(this)
                            .find('div')
                            .hasClass('menu-level-2-container')
                    ) {
                        $('.menu-level-1-container').addClass('level-2-active');
                        $(this)
                            .find('.menu-level-2-container')
                            .addClass('menu-active-container');
                    }
                })
                .on('mouseleave', function () {
                    $('.menu-main-container').removeClass('active');
                    // FM, GG, PF has: $(this).removeClass('active');
                    $('.menu-level-1-container').removeClass('level-2-active');
                    $(this)
                        .find('.menu-level-2-container')
                        .removeClass('menu-active-container');
                });
        }
    }

    $(window).on(
        'resize',
        ld.debounce(function () {
            loadMenu();
        }, 200)
    );

    $(document).ready(function () {
        $('.header-hamburger-menu-icon').on('click', function () {
            loadMenu();
            openCurrentCategory();
        });
    });

    loadMenu();
    openCurrentCategory();
};

module.exports = menu;
