'use strict';

// eslint-disable-next-line no-unused-vars
var base = require('base/components/footer');
// eslint-disable-next-line no-unused-vars
var scrollAnimate = require('./scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) { // eslint-disable-line no-unused-vars
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

function validEmail(newsletterEmail) {
    // eslint-disable-next-line no-useless-escape
    var pattern = /^[a-zA-Z0-9]*([\w+-]+\.)*[\w+-]+@[a-zA-Z0-9]([\w-]+\.)*[a-zA-Z]{2,6}$/;
    return pattern.test(String(newsletterEmail).toLowerCase());
}

module.exports = function () {
    // Not SFRA 6x script upgrade compatible.Selective changes applied.
    $('.newsletterForm').submit(function (e) {
        e.preventDefault();
        var form = $(this);
        var newsletterEmail = form.find('.newsletter-input');
        var newsletterEmailValue = newsletterEmail.val();
        var successMessage = form.find('.newsletter-message .newsletter-success-message');
        var errorMessage = form.find('.newsletter-error-message');
        var errorMessageText = '';

        var isValid = validEmail(newsletterEmailValue);

        if (!isValid) {
            errorMessageText = $(newsletterEmail).data('missing-error');
            newsletterEmail.val('');

            successMessage.addClass('hidden');
            $(errorMessage).text(errorMessageText);
            errorMessage.removeClass('hidden');
            return false;
        }

        grecaptcha.ready(function () { // eslint-disable-line
            grecaptcha.execute(form.attr('data-recaptcha-site-key'), { action: 'newsletter' }).then(function (token) { // eslint-disable-line
                $.ajax({
                    url: form.attr('action'),
                    method: 'POST',
                    data: newsletterEmail.serialize() + '&token=' + token + '&action=newsletter',
                    success: function (data) {
                        if (data.alreadySubscribed) {
                            successMessage.addClass('hidden');
                            $(errorMessage).text(data.alreadySubscribedMessage);
                            errorMessage.removeClass('hidden');
                            newsletterEmail.val('');
                        } else {
                            errorMessage.addClass('hidden');
                            successMessage.removeClass('hidden');
                            newsletterEmail.val('');
                        }

                        return true;
                    },
                    error: function () {
                        successMessage.addClass('hidden');
                        errorMessageText = $(newsletterEmail).data('missing-error');
                        $(errorMessage).text(errorMessageText);
                        errorMessage.removeClass('hidden');
                        newsletterEmail.val('');
                    }
                });
            });
        });
    });


    $('.click-to-expand').on('click', function () {
        $(this).closest('tr').next().toggle();
    });
};
